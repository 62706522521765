import React from "react";

const Contact = () => {
  return (
    <section id="contact">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-lg-offset-2 text-center">
            <h2
              className="margin-top-0 wow fadeIn"
              style={{
                visibility: "visible",
                animationName: "fadeIn",
                fontFamily: "Garamond",
                fontWeight: "bold",
              }}
            >
              Contact
            </h2>
            <hr className="primary" />
            <p style={{ fontFamily: "Garamond", fontSize: 18 }}>
              Vous souhaitez prendre contact avec l’association, nous vous
              remercions de compléter ce formulaire :
            </p>
          </div>
          <div className="col-lg-10 col-lg-offset-1 text-center">
            <form id="formulaire_contact" className="contact-form row">
              <div className="col-md-4">
                <label></label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  className="form-control"
                  placeholder="Nom"
                  required
                />
              </div>
              <div className="col-md-4">
                <label></label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  className="form-control"
                  placeholder="Prenom"
                  required
                />
              </div>
              <div className="col-md-4">
                <label></label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="col-md-12">
                <label></label>
                <input
                  type="text"
                  id="objet"
                  name="objet"
                  className="form-control"
                  placeholder="Objet"
                  required
                />
              </div>
              <div className="col-md-12">
                <label></label>
                <textarea
                  id="message"
                  name="message"
                  className="form-control"
                  rows="9"
                  placeholder="Votre message..."
                  required
                ></textarea>
              </div>
              <div className="col-md-4 col-md-offset-4">
                <label></label>
                <button
                  type="button"
                  id="submit"
                  name="submit"
                  className="btn btn-primary btn-block"
                >
                  Envoyer
                  <i className="ion-android-arrow-forward"></i>
                </button>
              </div>
              <label id="info"></label>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
