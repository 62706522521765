import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="site-footer bg-dark text-white mt-5">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12">
                        <div className="pt-5">
                            <p>
                                2020 © Tous droits réservés |
                                <i
                                    className="icon-heart"
                                    aria-hidden="true"
                                ></i>
                                <a
                                    href="https://heraldica.palisep.fr"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Palisep
                                </a>
                                | <Link to="/admin/login">ADMIN</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
