import axios from "axios";
import { address } from "./constants";

export const callApi = async (setLoading) => {
  const res = await axios.get(`${address}/`);
  if (res.data) {
    setLoading(false);
  }
};

export const callApropos = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/apropos`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const callHeader = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/header`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

// TODO could be good to add a visual feedback, maybe a toast?
export const saveApropos = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/apropos`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the data");
  }
};

export const saveHeader = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/header`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the data");
  }
};

export const saveIntro = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/intro`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the data");
  }
};

export const callIntro = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/intro`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const callFirst = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/first`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const saveFirst = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/first`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the data");
  }
};

export const callSecond = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/second`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const saveSecond = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/second`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the data");
  }
};

export const callSpecify = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/specify`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const saveSpecify = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/specify`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the data");
  }
};

export const callCalender = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/calender`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const callLieu = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/lieu`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const callTarifs = async (setValue, setLoading) => {
  const res = await axios.get(`${address}/tarifs`);
  if (res.data) {
    setValue(res.data);
    setLoading(false);
  }
};

export const saveCalender = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/calender`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the calender");
  }
};

export const saveTarifs = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/tarifs`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the tarifs");
  }
};

export const saveLieu = async (newValue) => {
  try {
    const response = await axios.post(`${address}/save/lieu`, {
      text: newValue,
    });
    console.log(response);
  } catch (e) {
    console.log("failed saving the lieu");
  }
};
