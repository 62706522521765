import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import './assets/styles/layout.css'
import Nav from './components/Nav'
import APropos from './components/APropos'
import Header from './components/Header'
import Cours from './components/Cours'
import Inscription from './components/Inscription'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Login from './components/admin/Login'
import { callApi } from './CallApi'
import { CircularProgress } from '@material-ui/core'

const App = () => {
    const [connected, setConnected] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        callApi(setLoading)
    }, [])

    return loading ? (
        <CircularProgress color="inherit" />
    ) : (
        <Switch>
            <Route exact path="/">
                <React.Fragment>
                    <Nav />
                    <Header />
                    <div className="container">
                        <APropos connected={connected} />
                        <Cours connected={connected} />
                        <Inscription connected={connected} />
                        <Contact connected={connected} />
                    </div>
                    <Footer connected={connected} />
                </React.Fragment>
            </Route>
            <Route exact path="/admin/login">
                <Login connected={connected} setConnected={setConnected} />
            </Route>
        </Switch>
    )
}

export default App
