import React from "react";
/* import heraldique_fond from "../assets/images/demo/backgrounds/heraldique_fond.jpg";
    <img
        className="img-fluid d-block mx-auto"
        src={heraldique_fond}
        alt=""
        style={{ width: "100%" }}
      />*/

const Header = () => {
  return <header className="bg-image-full"></header>;
};
export default Header;
