import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { address } from '../../constants'

const callApi = async (username, password, setConnected, history) => {
    const res = await axios.post(
        `${address}/auth?username=${username}&password=${password}`
    )
    if (res.data === 'OK!') {
        setConnected(true)
        console.log('connected')
        history.push('/')
    }
}

const Login = ({ connected, setConnected }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const history = useHistory()
    useEffect(() => {
        if (connected) history.push('/')
    }, [connected, history])

    const handleOnChangeUsername = (event) => {
        setUsername(event.target.value)
    }

    const handleOnChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleClick = (e) => {
        e.preventDefault()
        callApi(username, password, setConnected, history)
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6" style={{ paddingTop: '15rem' }}>
                    <form>
                        <h3>Authentification</h3>

                        <div className="form-group">
                            <label>Username</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le username"
                                value={username}
                                onChange={handleOnChangeUsername}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Mot de passe</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Enter le mot de passe"
                                value={password}
                                onChange={handleOnChangePassword}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={handleClick}
                        >
                            Submit
                        </button>
                    </form>
                </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    )
}

export default Login
