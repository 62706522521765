import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import trente_chateau_71 from "../assets/images/heraldico.PNG";
import imgHeader from "../assets/images/imgHeader.PNG";
import parse from "html-react-parser";
import { modules } from "../modules";
import { callApropos, callHeader, saveApropos, saveHeader } from "../CallApi";
import Skeleton from "react-loading-skeleton";

const APropos = ({ connected }) => {
  const [header, setHeader] = useState(``);
  const [value, setValue] = useState(``);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState("LOADED");

  useEffect(() => {
    callHeader(setHeader, setLoading);
    callApropos(setValue, setLoading);
  }, []);

  useEffect(() => {
    if (connected && updating === "ENDED_HEADER") {
      const timeOutId = setTimeout(() => saveHeader(header), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [header, connected, updating]);

  useEffect(() => {
    if (connected && updating === "ENDED") {
      const timeOutId = setTimeout(() => saveApropos(value), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [value, connected, updating]);

  const handleHeader = (text) => {
    setHeader(text);
    setUpdating("CHANGING");
  };

  const handleChange = (text) => {
    setValue(text);
    setUpdating("CHANGING");
  };

  return (
    <section id="a_propos" className="row">
      <nav
        id="topbar"
        className="navbar navbar-expand"
        style={{ width: 1500, background: "#f4ecd5" }}
      >
        <div className="col-lg-3 col-sm-4 col-8 offset-2 offset-sm-0">
          <img
            src={imgHeader}
            className="rounded img-shadow img-fluid"
            alt="wrapkit"
          />
        </div>
        <div className="col-lg-6 col-sm-7 mt-lg-4 ml-lg-5 col-12">
          <h2
            className="title"
            style={{ fontFamily: "Garamond", fontSize: 40, fontWeight: "bold" }}
          >
            Cours d'héraldique
          </h2>
          <hr className="primary mb-5" />
          <div
            className="text-box"
            style={{ fontFamily: "Garamond", fontSize: 20, fontWeight: "bold" }}
          >
            {loading ? (
              <div>
                <Skeleton count={7} />
              </div>
            ) : connected ? (
              <>
                <ReactQuill
                  theme="snow"
                  value={header}
                  onChange={handleHeader}
                  onBlur={() => setUpdating("ENDED_HEADER")}
                  modules={modules}
                />
              </>
            ) : (
              <>{parse(header)}</>
            )}
          </div>
        </div>
      </nav>
      <div
        className="col-lg-3 col-sm-4 col-8 offset-2 offset-sm-0"
        style={{ paddingTop: 20 }}
      >
        <img
          src={trente_chateau_71}
          className="rounded img-shadow img-fluid"
          alt="wrapkit"
        />
      </div>
      <div className="col-lg-6 col-sm-7 mt-lg-4 ml-lg-5 col-12">
        <h2
          className="title"
          style={{ fontFamily: "Garamond", fontWeight: "bold" }}
        >
          A propos
        </h2>
        <hr className="primary mb-5" />
        <div
          className="text-box"
          style={{ fontFamily: "Garamond", fontSize: 18 }}
        >
          {loading ? (
            <div>
              <Skeleton count={7} />
            </div>
          ) : connected ? (
            <>
              <ReactQuill
                theme="snow"
                value={value}
                onChange={handleChange}
                onBlur={() => setUpdating("ENDED")}
                modules={modules}
              />
            </>
          ) : (
            <>{parse(value)}</>
          )}
        </div>
      </div>
    </section>
  );
};
export default APropos;
